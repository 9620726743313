(function ($) {
    'use strict';

    window.scala.documentReady.then(function () {

        // Navigation principale
        var $newsContent = $(".news-feed"),
            $canvas = $newsContent.find(".news-feed__wrapper"),
            $slidingBloc = $newsContent.find(".news-feed__list"),
            canvasWidth = 0,
            slidingBlocWidth = 0,
            scrollingDistance = 0,
            incr = 0,
            direction = 'forward',

        // Refresh the size of bloc
        boxSizeRefresh = function(){
            canvasWidth = $canvas.outerWidth();
            slidingBlocWidth = $slidingBloc.outerWidth();
            scrollingDistance = slidingBlocWidth - canvasWidth;
        },

        rewind =  function(){
            direction = 'backward';
            if (incr >= 0) {
                $slidingBloc.animate({left: '+=20px'}, 600, function(){
                    incr -= 20;
                    rewind();
                });
            } else {
                play();
            }
        },

        play =  function(){
            direction = 'forward';
            if (incr < scrollingDistance) {
                $slidingBloc.animate({left: '-=20px'}, 600, function(){
                    incr += 20;
                    play();
                });
            } else {
                rewind();
            }
        },

        // Pause on hover
        onHover =  function(){
            $slidingBloc.hover( function(){
                $slidingBloc.stop();
            }, function() {
                if (direction == 'forward') {
                    play();
                } else {
                    rewind();
                }
            })
        },

        init = function(){
            boxSizeRefresh();
            play();
            onHover();
            $(window).resize( function(){
                boxSizeRefresh();
            });
        };

        init();
    });
}(jQuery.noConflict()));
